
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Card from "primevue/card";
import DataTable, {
  DataTablePageEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import MessageBox from "@/components/MessageBox.vue";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import SendReceiptDialog from "@/components/Pos/SendReceiptDialog.vue";

import Utils from "@/utility/utils";
import PaymentService from "@/services/PaymentService";
import CashService from "@/services/Cash";
import { FilterMatchMode } from "primevue/api";
import Cash from "@/types/cash";
import LocalDesktopService from "@/services/LocalDesktopService";

const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
const cashService = new CashService(process.env.VUE_APP_ABSTRACTION_API);
const localDesktopService = new LocalDesktopService();

export default defineComponent({
  name: "ReceiptHistory",
  components: {
    Card,
    DataTable,
    Column,
    Button,
    ProgressSpinner,
    Calendar,
    InputText,
    MessageBox,
    SendReceiptDialog,
    Dialog,
  },
  props: {
    payoutView: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      default: "",
    },
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => ({
    showVoidTransactionActions: false,
    showEmailDialog: false,
    transactionToSend: {} as Cash,
    showConfirmVoid: false,
    transactionToVoid: {} as Cash,
    voidedTransaction: {} as Cash,
    sortBy: "post.date",
    orderRecordsBy: "DEC",
    order: false,
    cashIdFilter: "",
    dueDateFilter: null as any,
    rowsPerPage: 10,
    sortOrder: -1,
    rangeStart: 1,
    rangeEnd: 100,
    sortField: "sortableDate",
    token: "",
    expiry: "",
    filters: {
      cash_id: { value: null, matchMode: FilterMatchMode.EQUALS },
      post_date: { value: [], matchMode: FilterMatchMode.BETWEEN },
    },
    page: 0,
    first: 0,
    cash: [] as Cash[],
    isLoadingCash: false,
    isLoading: {
      pdf: [] as string[],
      print: [] as string[],
      voidTransaction: [] as string[],
    },
    icons: {
      pdf: "pi pi-download",
      print: "pi pi-print",
      voidTransaction: "pi pi-ban",
    },
  }),
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      getUser: "session/getUser",
      getReg: "pos/getRegister",
      getPOSRegisterPrinter: "pos/getRegisterPrinter",
    }),
    isPrintVoidReceiptBtnDisabled() {
      return this.getReg === null;
    },
  },
  created() {
    this.fetchFilteredCashDate(false);
  },
  watch: {
    customerId() {
      this.fetchFilteredCashDate(false);
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    formatExpDate(dateString: string) {
      return Utils.formatExpDateString(dateString);
    },
    formatCCNumber(ccNumber: string) {
      if (ccNumber && ccNumber !== "") {
        return "************" + ccNumber?.substring(ccNumber?.length - 4);
      }
    },
    handleClearFilter(
      filter: "cashIdFilter" | "dueDateFilter",
      value: string | null | number,
    ) {
      this[filter] = value;
      this.rangeEnd = 100;
      this.fetchFilteredCashDate(false);
    },
    isLoadingElement(
      element: "pdf" | "print" | "voidTransaction",
      cashId: string,
    ) {
      return this.isLoading[element].includes(cashId);
    },
    getIconClass(element: "pdf" | "print" | "voidTransaction", cashId: string) {
      return {
        "pi pi-spin pi-spinner": this.isLoadingElement(element, cashId),
        [this.icons[element]]: !this.isLoadingElement(element, cashId),
      };
    },
    removeDownloadingElement(
      element: "pdf" | "print" | "voidTransaction",
      cashId: string,
    ) {
      this.isLoading[element] = this.isLoading[element].filter(
        (id: string) => id !== cashId,
      );
    },
    addDownloadingElement(
      element: "pdf" | "print" | "voidTransaction",
      cashId: string,
    ) {
      this.isLoading[element].push(cashId);
    },
    rowClicked(event: any) {
      this.token = event.data.creditcard_no_items[0].credit_card_id;
      this.expiry = event.data.creditcard_no_items[0].creditcard_exp;

      this.$emit("row-clicked", {
        token: this.token,
        expiry: this.expiry,
      });
    },
    getCashRecords() {
      this.isLoadingCash = true;
      cashService
        .getCash(
          this.cashIdFilter || "",
          this.customerId ? this.customerId : this.getCustomer.cust_id,
          this.dueDateFilter &&
            this.dueDateFilter.length > 0 &&
            (this.dueDateFilter !== null || this.dueDateFilter[0] !== "")
            ? this.formatDate(this.dueDateFilter[0])
            : "",
          this.dueDateFilter &&
            this.dueDateFilter.length > 1 &&
            (this.dueDateFilter !== null || this.dueDateFilter[1] !== "")
            ? this.formatDate(this.dueDateFilter[1])
            : "",
          this.rangeStart,
          this.rangeEnd,
          this.sortBy,
          this.orderRecordsBy,
          this.getReg?.reg_id,
          this.payoutView ? "CC" : "",
        )
        .then((response: any) => {
          if (response.cash_items) {
            this.cash = response.cash_items;
            this.cash.forEach((record: any) => {
              record.sortableDate = record.post_date
                ? new Date(record.post_date)
                : "";
            });
          } else {
            this.cash = [];
          }
        })
        .finally(() => {
          this.isLoadingCash = false;
        });
    },
    clickVoidTransaction(transaction: any) {
      this.showConfirmVoid = true;
      this.transactionToVoid = transaction;
    },
    handleVoidTransaction(data: Cash) {
      if (this.isLoadingElement("voidTransaction", data.cash_id)) return;
      this.addDownloadingElement("voidTransaction", data.cash_id);
      let voidedRecord = JSON.parse(JSON.stringify(data));
      voidedRecord.status = "R";
      voidedRecord.user = this.getUser.user_id;
      this.showConfirmVoid = false;

      cashService
        .updateCash(data.cash_id, voidedRecord, data)
        .then((response: any) => {
          if (response.status === "success") {
            this.voidedTransaction.cash_id = response.details[0]?.recordId;

            //update record status in table
            var foundIndex = this.cash.findIndex(
              (x) => x.cash_id == data.cash_id,
            );
            this.cash[foundIndex] = voidedRecord;

            this.showVoidTransactionActions = true;
          }
        })
        .finally(() => {
          this.removeDownloadingElement("voidTransaction", data.cash_id);
        });
    },
    formatPrice(price: number | string) {
      return Utils.formatPrice(price);
    },
    handlePrintReceipt(data: Cash) {
      this.addDownloadingElement("print", data.cash_id);
      paymentService
        .getReceipt({
          key: data.cash_id,
          user: this.getUser.user_id,
          reg: this.getReg.reg_id,
          email: "",
          format: "escpos",
        })
        .then((response: any) => {
          localDesktopService.printReceipt(
            response,
            this.getPOSRegisterPrinter,
          );
        })
        .catch(() => {
          const notification = {
            message: `Error printing receipt ${data.cash_id}`,
            type: "error",
          };
          this.addNotification(notification);
        })
        .finally(() => {
          this.removeDownloadingElement("print", data.cash_id);
          this.showVoidTransactionActions = false;
        });
    },
    handleDownloadReceipt(data: Cash) {
      this.addDownloadingElement("pdf", data.cash_id);
      paymentService
        .getReceipt({ key: data.cash_id, user: this.getUser.user_id })
        .then((response: any) => {
          const bufferArray = Utils.base64ToArrayBuffer(response);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
        })
        .catch((err) => {
          this.addNotification({
            message: `Failed to download receipt.`,
            type: "error",
          });
        })
        .finally(() => {
          this.removeDownloadingElement("pdf", data.cash_id);
        });
    },
    getPaymentTypeTitle(paymentType: any, paymentCheck: any) {
      let method = "";
      if (paymentType === "ACH") method = "e-CHECK";
      else if (paymentType !== undefined) method = paymentType;
      if (
        paymentType !== undefined &&
        paymentCheck !== undefined &&
        paymentType !== paymentCheck
      )
        method += " " + paymentCheck;
      else if (paymentCheck !== undefined) method = paymentCheck;
      return method;
    },
    formatDate(date: string | Date): string {
      return Utils.formatDate(date);
    },
    pageClick(event: DataTablePageEvent) {
      this.page = event.page;
      this.first = event.first;
      this.rowsPerPage = event.rows;
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1) &&
        this.cash.length == this.rangeEnd
      ) {
        this.rangeEnd = this.rangeEnd + 100;
        this.fetchFilteredCashDate(true);
      }
    },
    fetchFilteredCashDate(addRecords: boolean) {
      if (
        this.dueDateFilter &&
        this.dueDateFilter.length > 1 &&
        this.dueDateFilter[1] == null
      ) {
        this.dueDateFilter[1] = this.dueDateFilter[0];
      }
      this.getCashRecords();

      if (!addRecords) {
        this.page = 1;
        this.first = 0;
      }
    },
    sortData(event: DataTableSortEvent) {
      this.rowsPerPage = event.rows;
      this.order = this.order !== true;
      if (this.sortField && this.sortField !== event.sortField) {
        this.rangeEnd = 100;
      }

      if (event.sortField === "cash_id") {
        this.sortField = "cash_id";
        this.sortBy = "cash.id";
      } else if (event.sortField === "sortableDate") {
        this.sortField = "sortableDate";
        this.sortBy = "post.date";
      } else if (event.sortField === "payment_type") {
        this.sortField = "payment_type";
        this.sortBy = "payment.type";
      } else if (event.sortField === "check_amount") {
        this.sortField = "check_amount";
        this.sortBy = "check.amount";
      } else {
        this.order = false;
        this.sortField = "";
        this.sortBy = "post.date";
        this.orderRecordsBy = "DEC";
        this.sortOrder = -1;
        this.rangeEnd = 100;
      }

      if (this.order == true && event.sortField !== null) {
        //blank string will cause api to sort by ascending order
        this.orderRecordsBy = "";
        this.sortOrder = 1;
      } else {
        this.orderRecordsBy = "DEC";
        this.sortOrder = -1;
      }
      this.fetchFilteredCashDate(false);
    },
  },
});
