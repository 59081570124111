import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid col-12 mr-0 mt-1 ml-0 justify-content-between align-items-start" }
const _hoisted_2 = { class: "col flex mb-1 p-0 m-0 align-items-center" }
const _hoisted_3 = { class: "col flex p-0 m-0 justify-content-start md:justify-content-end pr-2 pl-5" }
const _hoisted_4 = { class: "p-inputgroup mt-2" }
const _hoisted_5 = { class: "p-inputgroup mt-2" }
const _hoisted_6 = { class: "p-inputgroup mt-2" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = { class: "menutab-item" }
const _hoisted_9 = { class: "menutab-item-link" }
const _hoisted_10 = { class: "menutab-item" }
const _hoisted_11 = { class: "menutab-item-link" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "menutab-item" }
const _hoisted_15 = { class: "menutab-item-link" }
const _hoisted_16 = { class: "menutab-item" }
const _hoisted_17 = { class: "menutab-item-link" }
const _hoisted_18 = { class: "grid m-0 justify-content-center" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "flex justify-content-end align-items-center" }
const _hoisted_21 = { class: "menutab-item" }
const _hoisted_22 = { class: "menutab-item-link" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 1,
  class: "emphasized"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_RecentlyViewedCustomers = _resolveComponent("RecentlyViewedCustomers")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_SaleOppsTabView = _resolveComponent("SaleOppsTabView")!
  const _component_SalesDataWrapper = _resolveComponent("SalesDataWrapper")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Search, {
          ref: "cust",
          label: "name",
          file: _ctx.searchFile,
          currentView: "Customers",
          placeholder: "Search Customer, Invoice #, or Order #",
          "minimum-characters": 1,
          leftIcon: true,
          class: "large-search-input",
          autofocus: "",
          onKeyup: _withKeys(_ctx.handleCustomerSearch, ["enter"]),
          onCustomerSearch: _ctx.handleCustomerSearch,
          onLookupSearch: _ctx.handleLookupSearch
        }, null, 8, ["file", "onKeyup", "onCustomerSearch", "onLookupSearch"]),
        (!_ctx.hideLoadCustomerButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "ml-1",
              label: "Load Customers",
              icon: "pi pi-users",
              style: { minWidth: '170px' },
              onClick: _ctx.handleLoadMoreCustomers
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.allowCustomerCreation)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "ml-1",
              label: "New Customer",
              icon: "pi pi-user-plus",
              style: { minWidth: '170px' },
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddCustomerDialog = true))
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.pos.register)
          ? (_openBlock(), _createBlock(_component_SplitButton, {
              key: 0,
              class: "align-content-center",
              label: "Register Actions",
              model: _ctx.regActions,
              style: { minWidth: '185px' },
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick())),
              ref: "splitButton"
            }, null, 8, ["model"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_Dialog, {
      header: "New Customer",
      visible: _ctx.showAddCustomerDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showAddCustomerDialog) = $event)),
      modal: ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Create",
          icon: "pi pi-check",
          onClick: _ctx.createCust,
          disabled: !_ctx.input
        }, null, 8, ["onClick", "disabled"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _cache[16] || (_cache[16] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Name", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.input,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.input) = $event)),
            id: "cust",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[17] || (_cache[17] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' },
            type: "tel"
          }, "Phone", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.customerPhone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customerPhone) = $event)),
            id: "phone",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showCashAdd,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showCashAdd) = $event)),
      modal: ""
    }, {
      header: _withCtx(() => _cache[18] || (_cache[18] = [
        _createTextVNode(" Add Cash to Drawer ")
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Submit",
          icon: "pi pi-check",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addLoanAndPay()))
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _cache[19] || (_cache[19] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Charge", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.loanCharge,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.loanCharge) = $event)),
            id: "reg",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showCashRemove,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showCashRemove) = $event)),
      modal: ""
    }, {
      header: _withCtx(() => _cache[20] || (_cache[20] = [
        _createTextVNode(" Withdraw Cash from Drawer ")
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Submit",
          icon: "pi pi-check",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.addPUandPay()))
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _cache[21] || (_cache[21] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Charge", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.puCharge,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.puCharge) = $event)),
            id: "reg",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_TabView, {
      class: "customers-menu-tabview",
      scrollable: "",
      onTabChange: _ctx.handleCustomerMenuTabChange,
      activeIndex: _ctx.customerSearchTab
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, null, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_Button, {
                  label: "Recently Viewed",
                  class: "menutab-button"
                })
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _createVNode(_component_RecentlyViewedCustomers, { onCustomerRowClick: _ctx.rowClick }, null, 8, ["onCustomerRowClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, null, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_Button, {
                  label: "Search Results",
                  class: "menutab-button",
                  badge: _ctx.customerBadgeCount
                }, null, 8, ["badge"])
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                (_ctx.getPosCustomerLookup)
                  ? (_openBlock(), _createBlock(_component_LookupDataTable, {
                      key: 0,
                      ref: "customerLookup",
                      lookup: _ctx.getCustomerLookup,
                      params: _ctx.lookupParams,
                      lazyLoadPagination: true,
                      tableClass: "p-datatable-sm",
                      columnHeaderClass: "font-bold text-base",
                      onRowClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.rowClick({ data: $event }))),
                      onOnLoad: _ctx.handleLookupData
                    }, null, 8, ["lookup", "params", "onOnLoad"]))
                  : (_openBlock(), _createBlock(_component_DataTable, {
                      key: 1,
                      dataKey: "cust_id",
                      value: _ctx.getAllCustomers.cust_items,
                      paginator: true,
                      rowsPerPageOptions: [50, 100, 150],
                      rows: _ctx.rows,
                      selectionMode: "single",
                      onPage: _ctx.pageClick,
                      breakpoint: "1267px",
                      onRowClick: _ctx.rowClick,
                      class: "p-datatable-sm"
                    }, {
                      empty: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createTextVNode(" No rows to show. ")
                      ])),
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          field: "cust_id",
                          header: "ID",
                          headerClass: "column-header-left"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("span", null, _toDisplayString(slotProps.data.custno || slotProps.data.cust_id), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "name",
                          header: "Customer Name",
                          headerClass: "column-header-left"
                        }),
                        (_ctx.showServiceRep)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 0,
                              field: "service_rep",
                              header: "Service Rep"
                            }))
                          : (_openBlock(), _createBlock(_component_Column, {
                              key: 1,
                              field: "rep_items",
                              header: "Reps",
                              headerClass: "column-header-left"
                            }, {
                              body: _withCtx((slotProps) => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rep_items, (rep, index) => {
                                  return (_openBlock(), _createElementBlock("span", { key: index }, [
                                    _createTextVNode(_toDisplayString(rep.rep_name) + " ", 1),
                                    (index < slotProps.data.rep_items.length - 1)
                                      ? (_openBlock(), _createElementBlock("br", _hoisted_12))
                                      : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            })),
                        _createVNode(_component_Column, {
                          header: "Shipping Address",
                          headerClass: "column-header-left",
                          bodyClass: "font-normal text-left"
                        }, {
                          body: _withCtx((slotProps) => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingAddress(slotProps.data), (address, i) => {
                              return (_openBlock(), _createElementBlock("span", { key: i }, [
                                _createTextVNode(_toDisplayString(address) + " ", 1),
                                _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1))
                              ]))
                            }), 128))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          field: "phone",
                          header: "Phone #",
                          headerClass: "column-header-right",
                          bodyStyle: "text-align: right"
                        }, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("a", {
                              href: 'tel:' + slotProps.data.phone
                            }, _toDisplayString(slotProps.data.phone), 9, _hoisted_13)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value", "rows", "onPage", "onRowClick"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.opportunitiesTabAccess)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 0 }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, [
                    _createVNode(_component_Button, {
                      "data-testid": "pos-opportunities-tab-button",
                      label: "Opportunities",
                      class: "menutab-button",
                      badge: _ctx.totalOpportunities
                    }, null, 8, ["badge"])
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createVNode(_component_SaleOppsTabView, {
                      onUpdateBadge: _cache[12] || (_cache[12] = ($event: any) => (_ctx.totalOpportunities = $event)),
                      defaultRoute: "/pos/customers"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.posShowOrdersTab)
          ? (_openBlock(), _createBlock(_component_TabPanel, { key: 1 }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, [
                    _createVNode(_component_Button, {
                      "data-testid": "pos-orders-tab-button",
                      label: "Orders",
                      class: "menutab-button",
                      badge: _ctx.formattedTotalOrderRecords
                    }, null, 8, ["badge"])
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Card, null, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _withDirectives(_createVNode(_component_Button, {
                            class: "split-sales-order-btn",
                            icon: "pi pi-filter-slash",
                            outlined: "",
                            onClick: _ctx.clearFilters
                          }, null, 8, ["onClick"]), [
                            [_directive_tooltip, 'Clear All Filters']
                          ]),
                          _createVNode(_component_Button, {
                            class: "split-sales-order-btn",
                            label: "Print/Export",
                            icon: "pi pi-print",
                            onClick: _ctx.printExportData
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_Button, {
                            class: "ml-1 my-2",
                            label: "Load All Orders",
                            icon: "pi pi-search",
                            onClick: _ctx.loadOrders
                          }, null, 8, ["onClick"])
                        ])
                      ])
                    ]),
                    _createVNode(_component_SalesDataWrapper, {
                      ref: "orderTable",
                      isResultView: _ctx.isResultsListView,
                      maxExportRows: 1000,
                      printExportData: _ctx.printExportData,
                      clearFilters: _ctx.clearFilters,
                      loadOrders: _ctx.loadOrders,
                      onUpdateTotalRecords: _ctx.handleTotalOrderRecords,
                      lookupName: _ctx.getPosSearchOrdersLookup
                    }, null, 8, ["isResultView", "printExportData", "clearFilters", "loadOrders", "onUpdateTotalRecords", "lookupName"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_TabPanel, null, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, [
                (!_ctx.hideDraftedOrders)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: "Draft Orders",
                      class: "menutab-button",
                      badge: _ctx.unsavedOrdersCount
                    }, null, 8, ["badge"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Card, null, {
              content: _withCtx(() => [
                _createVNode(_component_DataTable, {
                  dataKey: "cust_id",
                  value: _ctx.unsavedOrders,
                  paginator: true,
                  rowsPerPageOptions: [50, 100, 150],
                  rows: _ctx.rows,
                  selectionMode: "single",
                  onPage: _ctx.pageClick,
                  onRowClick: _ctx.handleLoadUnsavedOrder,
                  breakpoint: "1267px",
                  class: "p-datatable-sm"
                }, {
                  empty: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode(" No rows to show. ")
                  ])),
                  header: _withCtx(() => [
                    false
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          icon: "pi pi-save",
                          label: "Save Orders",
                          onClick: _ctx.saveAllUnsavedOrders
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "order.so_id",
                      header: "Order ID",
                      headerClass: "column-header-left",
                      bodyClass: "font-normal text-left"
                    }, {
                      body: _withCtx((slotProps) => [
                        (slotProps.data.order.so_id)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(slotProps.data.order.so_id), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_24, "Draft"))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "order.sold_to",
                      header: "Customer ID",
                      headerClass: "column-header-left",
                      bodyClass: "font-normal text-left"
                    }),
                    _createVNode(_component_Column, {
                      field: "customer_name",
                      header: "Customer Name",
                      headerClass: "column-header-left",
                      bodyClass: "font-normal text-left"
                    }),
                    _createVNode(_component_Column, {
                      header: "Shipping Address",
                      headerClass: "column-header-left",
                      bodyClass: "font-normal text-left"
                    }, {
                      body: _withCtx((slotProps) => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.convertOrderShippingAddressToArray(
                    slotProps.data.order,
                  ), (address, i) => {
                          return (_openBlock(), _createElementBlock("span", { key: i }, [
                            _createTextVNode(_toDisplayString(address) + " ", 1),
                            _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1))
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      header: "Total Quantity",
                      headerClass: "column-header-right",
                      bodyClass: "font-normal text-right"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createTextVNode(_toDisplayString(slotProps.data.order?.lis_items?.reduce(
                    (acc, item) => acc + +item.li_order_qtys,
                    0,
                  )), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "order.order_amount",
                      header: "Order Amount",
                      headerClass: "column-header-right",
                      bodyClass: "font-normal text-right"
                    }),
                    _createVNode(_component_Column, {
                      header: "Created",
                      headerClass: "column-header-left",
                      bodyClass: "font-normal text-left"
                    }, {
                      body: _withCtx((slotProps) => [
                        _createTextVNode(_toDisplayString(_ctx.printDateTime(slotProps.data.date_created)), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.showRacks)
                      ? (_openBlock(), _createBlock(_component_Column, {
                          key: 0,
                          header: "Racks",
                          headerClass: "column-header-left",
                          bodyClass: "font-normal text-left"
                        }, {
                          body: _withCtx((slotProps) => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.racks, (rack, i) => {
                              return (_openBlock(), _createElementBlock("span", { key: i }, [
                                _createTextVNode(_toDisplayString(rack.part_id) + ": " + _toDisplayString(_ctx.formatRacks(rack.rack_count)) + " ", 1),
                                _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1))
                              ]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Column, { header: "Actions" }, {
                      body: _withCtx((slotProps) => [
                        _createVNode(_component_Button, {
                          "aria-label": "Delete",
                          icon: "pi pi-trash",
                          class: "ml-1",
                          onClick: ($event: any) => {
                    _ctx.showDeleteDraftOrderDialog = true;
                    _ctx.selectedUnsavedOrderIndex = slotProps.index;
                  }
                        }, null, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "rows", "onPage", "onRowClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onTabChange", "activeIndex"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showCloseRegisterDialog,
      header: "Close Register",
      icon: "WARNING",
      message: "Are you sure you want to close this register?",
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _ctx.closeClear,
      onCancelButtonClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showCloseRegisterDialog = false))
    }, null, 8, ["showDialog", "onPrimaryButtonClick"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showDeleteDraftOrderDialog,
      header: "Delete Draft Order",
      icon: "WARNING",
      message: "Are you sure you want to remove this unsaved order? All progress will be lost.",
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _cache[14] || (_cache[14] = ($event: any) => (
      _ctx.handleRemoveUnsavedOrder({ index: _ctx.selectedUnsavedOrderIndex })
    )),
      onCancelButtonClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.showDeleteDraftOrderDialog = false))
    }, null, 8, ["showDialog"])
  ], 64))
}